import * as React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PageCopy from "../components/PageCopy";

const ArtworkInfo = styled.li`
  list-style: none;
  display: grid;
  grid-template-columns: 9rem 1fr;
  grid-template-areas:
    "img title"
    "img artist"
    "img material";
  padding: 1rem;
  border-radius: 0.5rem;
  &:hover {
    background-color: var(--brand-blue);
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    img {
      transform: scale(1.2);
      transition: transform 1.5s ease-in-out;
    }
  }
  img {
    transform: scale(1);
    transition: transform 1.5s ease-in-out;
    border-radius: 0.5rem;
  }
  div {
    width: 70px;
    grid-area: img;
  }
  h2 {
    grid-area: title;
    margin: 0;
  }
  p:nth-child(1) {
    grid-area: artist;
  }
  p:nth-child(2) {
    grid-area: material;
  }
  hr {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }
`;

export default function Art({ data }) {
  const artworks = data.artworks.nodes;
  const artworkCount = data.artworks.totalCount;
  const pageCopy = [
    data.pageCopy.artIntro.split("\n"),
    `There are ${artworkCount} pieces in the collection.`,
  ];

  return (
    <div className="pageContent">
      <h1>Artworks</h1>
      <PageCopy pageCopy={pageCopy} />
      <ul>
        {artworks.map((artwork, i) => {
          return (
            <>
              <Link to={artwork.slug.current}>
                <ArtworkInfo>
                  <GatsbyImage
                    className="image"
                    image={getImage(artwork.image1.asset)}
                    alt={`artwork.title`}
                  />
                  <h2>
                    {artwork.title}
                    {artwork.releaseDate
                      ? " (" + artwork.releaseDate + ")"
                      : ""}
                  </h2>
                  <p>{artwork.artist.name}</p>
                  <p>{artwork.medium}</p>
                </ArtworkInfo>
              </Link>
              <hr />
            </>
          );
        })}
      </ul>
    </div>
  );
}

export const query = graphql`
  query {
    artworks: allSanityArt {
      nodes {
        id
        title
        slug {
          current
        }
        releaseDate
        orientation
        medium
        locationY
        locationX
        artist {
          name
        }
        image1 {
          asset {
            gatsbyImageData(placeholder: DOMINANT_COLOR, height: 100)
          }
        }
      }
      totalCount
    }
    pageCopy: sanityWebsiteCopy {
      artIntro
    }
  }
`;
